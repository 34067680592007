.dropdown-container {
  margin: 20px auto;
  width: 300px;
}

.css-1okebmr-indicatorSeparator {
  display: none;
}

.css-1pahdxg-control {
  box-shadow: none;
  border-color: #dcdcdc;
}

.css-1pahdxg-control:hover {
  border-color: #dcdcdc;
}

.custom-color-picker {
  width: 100%;
}
.multi-color-dropdown__control{
  min-height: 3rem !important;
}