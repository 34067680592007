.good-data-date-filter {
  width: fit-content;
  border: 2px solid black;
  border-radius: 4px;
}
.gd-extended-date-filter-body {
  max-height: 28rem !important;
}
.good-data-date-filter .s-date-filter-button {
  z-index: 0;
}
.gd-overlay-content .gd-extended-date-filter-exclude-current {
  display: none;
}
.google-social-link .good-data-date-filter {
  width: 100%;
}
.google-social-link .good-data-date-filter .s-date-filter-button {
  margin: 0 0 0.5rem 0 !important;
}
.good-data-date-filter .s-date-filter-button {
  margin: 0 !important;
}
