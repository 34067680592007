@tailwind base;
@tailwind components;
@tailwind utilities;
@import "bootstrap/dist/css/bootstrap.min.css";

body {
  margin: 0;
  font-family: "Montserrat", sans-serif; /* Ensure Montserrat is used with fallback to generic sans-serif */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.toast-message {
  z-index: 10000 !important;
}

.system-bg-color {
  background-color: #146c43 !important;
}

input[type="radio"]:checked {
  background-color: #146c43 !important;
  box-shadow: none;
}
