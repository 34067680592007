@import "./sidebar.css";
@import "./colorSelector.css";
@import "./goodatafilter.css";

.truncate-text {
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
.table-cell {
  padding: 0.65rem 0 !important;
}
.multi-color-dropdown__menu{
  z-index: 100 !important;
}
